const middleware = {}

middleware['authenticated.middleware'] = require('../middleware/authenticated.middleware.js')
middleware['authenticated.middleware'] = middleware['authenticated.middleware'].default || middleware['authenticated.middleware']

middleware['is-not-admin.middleware'] = require('../middleware/is-not-admin.middleware.js')
middleware['is-not-admin.middleware'] = middleware['is-not-admin.middleware'].default || middleware['is-not-admin.middleware']

middleware['layout.middleware'] = require('../middleware/layout.middleware.js')
middleware['layout.middleware'] = middleware['layout.middleware'].default || middleware['layout.middleware']

middleware['not-authenticated.middleware'] = require('../middleware/not-authenticated.middleware.js')
middleware['not-authenticated.middleware'] = middleware['not-authenticated.middleware'].default || middleware['not-authenticated.middleware']

middleware['redirectOnError'] = require('../middleware/redirectOnError.js')
middleware['redirectOnError'] = middleware['redirectOnError'].default || middleware['redirectOnError']

middleware['seo.middleware'] = require('../middleware/seo.middleware.js')
middleware['seo.middleware'] = middleware['seo.middleware'].default || middleware['seo.middleware']

middleware['whoim.middleware'] = require('../middleware/whoim.middleware.js')
middleware['whoim.middleware'] = middleware['whoim.middleware'].default || middleware['whoim.middleware']

export default middleware
